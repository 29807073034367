import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React from "react";
import { unexpectedErrorAsset } from "../config/assets";
const ErrorMessageComponent = dynamic(() => import("../components/common/ErrorMessageComponent"))

export default function PageNotFound() {

    const router = useRouter();

    const handleRedirectBackToHome = () => {
        router.replace("/bobblexclusive/home");
    }

    return (
        <>
            <div className="error-container">
                <div className='error-message-container'>
                    <ErrorMessageComponent
                        errorMessage={'404! Page not found'}
                        image={unexpectedErrorAsset}
                    />
                    <a className='continue-shopping-button text-selection-none ' onClick={handleRedirectBackToHome}>
                        <span>Take me to Home</span>
                    </a>
                </div>
            </div>
        </>
    )
}